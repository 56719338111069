import { useEffect } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import QuickContactForm from "./QuickContactForm";

function CustomBroking() {

    useEffect(() => {
        loadScript("js/custom.js")
    }, [])

    return (
        <>
            {/* Service Detail */}
            <div className="section-full p-t80 p-b50">
                <div className="container bg-white">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="mt-box">
                                    <h2 className="m-t0">Custom Broking Services</h2>
                                    <h4>Streamlining the process of customs clearance for smooth and efficient international trade.</h4>
                                    <p>
                                        At Rumi Shipping and Logistics, our custom broking services ensure that your shipments pass through customs smoothly and efficiently. Our experienced team handles all aspects of customs documentation, compliance, and clearance, reducing the risk of delays and additional costs.
                                    </p>
                                    <p>
                                        We stay updated with the latest customs regulations and leverage our expertise to expedite the clearance process. Whether you are importing or exporting goods, we provide tailored solutions to meet your specific needs, ensuring your cargo reaches its destination without hassle.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20">
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/cb-pic1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/cb-pic2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/cb-pic3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full p-t80 p-b50 bg-gray">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head text-center">
                        <h2 data-title="Services">Custom Broking Services</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/cb-pic1.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Customs Clearance</h4>
                                        <p>Efficient and accurate customs clearance to ensure your goods move smoothly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/cb-pic2.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Documentation Support</h4>
                                        <p>Comprehensive support for all necessary documentation and compliance requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/cb-pic3.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Tariff Consultation</h4>
                                        <p>Expert advice on tariff classifications, duties, and taxes to optimize your shipping costs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section-full p-t80 p-b50 bg-white">
                <div className="container bg-white">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {/* Need Help */}
                                <div className="mt-box need-help m-b40">
                                    <h2>Need any help!</h2>
                                    <p className="m-b15"><b>Find answers to frequently asked questions about our custom broking services, contacts, and more.</b></p>
                                    <p>
                                        Rumi Shipping and Logistics is here to assist you with all your customs broking needs. Whether you need help with customs clearance, tracking your shipment, or understanding our services, we have the resources to help.
                                    </p>
                                    <ul className="list-check-circle primary m-b15">
                                        <li>Expert advice on international shipping and customs regulations.</li>
                                        <li>Comprehensive support for documentation and compliance.</li>
                                    </ul>
                                    <NavLink to={route.pages.contact.CONTACT} className="site-button-link">Contact Us</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                {/* Quick Contact */}
                                <QuickContactForm service={'Custom Broking'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomBroking;