import { useEffect } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import QuickContactForm from "./QuickContactForm";

function Transportation() {

    useEffect(() => {
        loadScript("js/custom.js")
    }, [])

    return (
        <>
            {/* Service Detail */}
            <div className="section-full p-t80 p-b50">
                <div className="container bg-white">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="mt-box">
                                    <h2 className="m-t0">Transportation Services</h2>
                                    <h4>Providing reliable and efficient transportation solutions for your cargo.</h4>
                                    <p>
                                        At Rumi Shipping and Logistics, our transportation services ensure your goods are moved safely and efficiently from point A to point B. We offer a range of transportation options, including road, rail, and intermodal solutions, to meet your specific needs.
                                    </p>
                                    <p>
                                        Our experienced team manages all aspects of the transportation process, from route planning and scheduling to tracking and delivery, ensuring your cargo arrives on time and in perfect condition.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20">
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/t-pic1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/t-pic2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/t-pic3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full p-t80 p-b50 bg-gray">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head text-center">
                        <h2 data-title="Services">Transportation Services</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/t-pic1.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Road Transport</h4>
                                        <p>Reliable road transport solutions to move your goods efficiently across regions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/t-pic2.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Sea Transport</h4>
                                        <p>Efficient Sea transport services to move large volumes of goods over long distances.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/t-pic3.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Intermodal Transport</h4>
                                        <p>Seamless intermodal solutions combining road, rail, and sea transport for efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full p-t80 p-b50 bg-white">
                <div className="container bg-white">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {/* Need Help */}
                                <div className="mt-box need-help m-b40">
                                    <h2>Need any help!</h2>
                                    <p className="m-b15"><b>Find answers to frequently asked questions about our transportation services, contacts, and more.</b></p>
                                    <p>
                                        Rumi Shipping and Logistics is here to assist you with all your transportation needs. Whether you need help with route planning, tracking your shipment, or understanding our services, we have the resources to help.
                                    </p>
                                    <ul className="list-check-circle primary m-b15">
                                        <li>Expert advice on transportation logistics and route optimization.</li>
                                        <li>Comprehensive support for scheduling and delivery coordination.</li>
                                    </ul>
                                    <NavLink to={route.pages.contact.CONTACT} className="site-button-link">Contact Us</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                {/* Quick Contact */}
                                <QuickContactForm service={'Transportation Services'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transportation;