import { Routes, Route } from "react-router-dom";
import { route } from "../globals/constants";
import Home1Page from "../app/components/home/home1";

import InnerPageLayout from "../layouts/inner-page-layout";
import AboutUsPage from "../app/components/pages/about-us";
import ContactUs2Page from "../app/components/pages/contact/contact-us2";
import Gallery1Page from "../app/components/pages/gallery/gallery1";
import Error404 from "../app/components/pages/error404";

import Freightforwarding from "../app/components/services/freightforwarding";
import CustomBroking from "../app/components/services/custombroking";
import Transportation from "../app/components/services/transportation";
import Warehousing from "../app/components/services/warehousing";
import LogisticsConsultants from "../app/components/services/logisticsconsultants";

function AppRoutes() {
    return (
        <Routes>
            <Route path={route.INITIAL} element={<Home1Page />}/>
            <Route path={route.home.HOME1} element={<Home1Page />}/>
            <Route path={route.pages.ABOUT} element={<InnerPageLayout content={<AboutUsPage />} />}/>
            <Route path={route.pages.contact.CONTACT} element={<InnerPageLayout content={<ContactUs2Page />} />}/>
            <Route path={route.pages.gallery.GALLERY} element={<InnerPageLayout content={<Gallery1Page />} />}/>
            <Route path={route.services.freightforwarding} element={<InnerPageLayout content={<Freightforwarding />} />}/>
            <Route path={route.services.custombroking} element={<InnerPageLayout content={<CustomBroking />} />}/>
            <Route path={route.services.transportation} element={<InnerPageLayout content={<Transportation />} />}/>
            <Route path={route.services.warehousing} element={<InnerPageLayout content={<Warehousing />} />}/>
            <Route path={route.services.logisticsconsultants} element={<InnerPageLayout content={<LogisticsConsultants />} />}/>

            {/* 
             <Route path={route.services.SERVICES} element={<InnerPageLayout content={<ServicesPage />} />}/>
            <Route path={route.home.HOME2} element={<Home2Page />}/>
            <Route path={route.home.HOME3} element={<Home3Page />}/>
            <Route path={route.pages.FAQ} element={<InnerPageLayout content={<FAQPage />} />}/>
            <Route path={route.pages.team.TEAM} element={<InnerPageLayout content={<TeamPage />} />}/>
            <Route path={route.pages.team.DETAIL} element={<InnerPageLayout content={<TeamDetailPage />} />}/>
            <Route path={route.pages.features.footer.FIXED} element={<InnerPageLayout content={<FeaturesPage />} />}/>
            <Route path={route.pages.features.footer.LIGHT} element={<InnerPageLayout content={<FeaturesPage />} />}/>
            <Route path={route.pages.features.footer.DARK} element={<InnerPageLayout content={<FeaturesPage />} />}/>
            <Route path={route.blog.LIST} element={<InnerPageLayout content={<BlogListPage />} />}/>
            <Route path={route.blog.LIST_SIDEBAR} element={<InnerPageLayout content={<BlogListSidebarPage />} />}/>
            <Route path={route.blog.GRID} element={<InnerPageLayout content={<BlogGridPage />} />}/>
            <Route path={route.blog.GRID_SIDEBAR} element={<InnerPageLayout content={<BlogGridSidebarPage />} />}/>
            <Route path={route.blog.POST_SINGLE} element={<InnerPageLayout content={<BlogPostSinglePage />} />}/>
            <Route path={route.shop.PRODUCTS} element={<InnerPageLayout content={<ShopProductsPage />} />}/>
            <Route path={route.shop.DETAIL} element={<InnerPageLayout content={<ShopProductDetailPage />} />}/>
            <Route path={route.shop.CART} element={<InnerPageLayout content={<ShopCartPage />} />}/>
            <Route path={route.shop.WISHLIST} element={<InnerPageLayout content={<ShopWishlistPage />} />}/>
            <Route path={route.shop.CHECKOUT} element={<InnerPageLayout content={<ShopChekoutPage />} />}/> 
            <Route path={route.ELEMENTS} element={<InnerPageLayout content={<ElementsPage />} />}/> */}
            <Route path="*" element={<InnerPageLayout content={<Error404 />} />}/>
        </Routes>
    )
}
export default AppRoutes;