import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";

function SectionHome1Testimonials() {
    return (
        <div className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})` }}>
            <div className="container">
                {/* TITLE START */}
                <div className="section-head text-center">
                    <h2 data-title="Testimonials">Our Clients Say</h2>
                    <div className="mt-separator-outer">
                        <div className="mt-separator site-bg-primary" />
                    </div>
                </div>
                {/* TITLE END */}
                <div className="section-content">
                    <div className="owl-carousel home-carousel-1">
                        <div className="item">
                            <div className="testimonial-2  clearfix bg-white">
                                <div className="testimonial-detail">
                                    <div className="testimonial-pic shadow"><FastImage src="images/testimonials/pic1.png" width={100} height={100} alt="" /></div>
                                </div>
                                <div className="testimonial-text">
                                    <strong className="testimonial-name">Rahul Sharma</strong>
                                    <span className="testimonial-position">Logistics Manager</span>
                                    <span className="fa fa-quote-left" />
                                    <p> Rumi Shipping and Logistics has provided exceptional service. Their attention to detail and customer support are outstanding.</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonial-2 clearfix bg-white">
                                <div className="testimonial-detail ">
                                    <div className="testimonial-pic shadow"><FastImage src="images/testimonials/pic2.png" width={100} height={100} alt="" /></div>
                                </div>
                                <div className="testimonial-text">
                                    <strong className="testimonial-name">Priya Mehta</strong>
                                    <span className="testimonial-position">Supply Chain Coordinator</span>
                                    <span className="fa fa-quote-left" />
                                    <p> The team at Rumi Shipping is incredibly professional. They ensured our shipments were delivered on time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonial-2  clearfix  bg-white">
                                <div className="testimonial-detail">
                                    <div className="testimonial-pic shadow"><FastImage src="images/testimonials/pic3.png" width={100} height={100} alt="" /></div>
                                </div>
                                <div className="testimonial-text">
                                    <strong className="testimonial-name">Amit Verma</strong>
                                    <span className="testimonial-position">Operations Manager</span>
                                    <span className="fa fa-quote-left" />
                                    <p> We have been using Rumi Shipping and Logistics for over a year, and their service is always reliable and efficient.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SectionHome1Testimonials;
