import axios from "axios";
import { useRef, useState } from "react";

const QuickContactForm = ({ service }) => {
    const name = useRef(null);
    const email = useRef(null);
    const mobileNo = useRef(null);
    const message = useRef(null);

    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            const res = await axios.post("/.netlify/functions/submitForm", {
                name: name.current.value,
                mobileNo: mobileNo.current.value,
                email: email.current.value,
                service: service,
                message: message.current.value,
                formType: 'CONTACT'
            });
            console.log('res', res)
            const response = await res.data;
            setResponse(response);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            setResponse('An unexpected Error Occurred');
        }
    };
    return (
        <div className="widget">
            <h2>Quick Contact</h2>
            <div className="widget-quick-form">
                <form>
                    <div className="form-group">
                        <input placeholder="Name" ref={name} className="form-control" type="text" />
                    </div>
                    <div className="form-group">
                        <input placeholder="Email" ref={email} className="form-control" type="text" />
                    </div>
                    <div className="form-group">
                        <input placeholder="MobileNo" ref={mobileNo} className="form-control" type="text" />
                    </div>
                    <div className="form-group">
                        <textarea placeholder="Message" ref={message} className="form-control" rows={3} defaultValue={""} />
                    </div>
                    <div className="form-group">
                        <button onClick={handleSubmit} disabled={loading} className="site-button text-uppercase font-weight-600">Submit</button>
                    </div>
                    {response && <div className="px-2 py-2 mt-5 cda-content-area" style={{
                        backgroundColor: '#020d26',
                        borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between', color: '#fff'
                    }}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
                </form>
            </div>
        </div>
    )
}
export default QuickContactForm;