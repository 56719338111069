import axios from "axios";
import { useRef, useState } from "react";

function ContactUs2Page() {
    const name = useRef(null);
    const email = useRef(null);
    const mobileNo = useRef(null);
    const service = useRef(null);
    const message = useRef(null);

    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            const res = await axios.post("/.netlify/functions/submitForm", {
                name: name.current.value,
                mobileNo: mobileNo.current.value,
                email: email.current.value,
                service: service.current.value,
                message: message.current.value,
                formType: 'CONTACT'
            });
            console.log('res', res)
            const response = await res.data;
            setResponse(response);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            setResponse('An unexpected Error Occurred');
      }
    };
    return (
        <>
            <div className="section-full p-t80 ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-6">
                            {/* Google Map Block */}
                            <div className="equal-col" style={{ height: '100%' }}>
                                <div className="gmap-outline"  style={{ height: '100%' }}>
                                    <div className="google-map" style={{ width: '100%', height: '100%' }}>
                                        <iframe style={{ height: '100%' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117697.3944043742!2d69.7043896!3d22.800849200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950d4e52b72a43f%3A0x3284ab5e3b82b02b!2sMundra%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1725714210818!5m2!1sen!2sin" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className=" bg-gray-light p-a30 radius-10 cons-contact-form-wrap">
                                <div className="section-head">
                                    <h2>Contact Form </h2>
                                    <div className="mt-separator-outer m-b30">
                                        <div className="mt-separator site-bg-primary" />
                                    </div>
                                </div>
                                <div className="mt-box">
                                    <form className="contact-style-2" onSubmit={()=>{}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input name="name" ref={name} type="text" required className="form-control" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input name="email" ref={email} type="text" className="form-control" required placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input name="mobileNo" ref={mobileNo} type="text" className="form-control" required placeholder="Mobile No" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                <select name="services" required ref={service} className="form-control" placeholder="Select Service" >
                                                    <option value="">Select Service</option>
                                                    <option value="Transportation Services">Transportation Services</option>
                                                    <option value="Custom Broking">Custom Broking</option>
                                                    <option value="Freight Forwarding">Freight Forwarding</option>
                                                    <option value="Logistics Consultancy Services">Logistics Consultancy Services</option>
                                                    <option value="Warehousing Services">Warehousing Services</option>
                                                </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea name="message" ref={message} rows={6} className="form-control Message" required placeholder="Message" defaultValue={""} />
                                                </div>
                                            </div>
                                          
                                            <div className="col-md-12 text-right">
                                                <button name="submit" disabled={loading} onClick={handleSubmit} value="Submit" className="site-button-secondry  m-r15">Submit</button>
                                                <button name="Resat" type="reset" value="Reset" className="site-button ">Reset </button>
                                            </div>
                                        </div>
                                        {response && <div className="px-2 py-2 mt-5 cda-content-area" style={{
                                            backgroundColor: '#ffffff',
                                            borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between', color: '#020d26'
                                        }}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t80 p-b50">
                <div className="container">
                    <div className="section-head">
                        <h2>Contact Detail </h2>
                        <div className="mt-separator-outer m-b30">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-icon-box-wraper bx-style-2 m-l20 m-b30 p-a30 left bg-white" style={{height: '100%'}}>
                                <div className="mt-icon-box-xs site-bg-primary m-b20">
                                    <span className="icon-cell text-white"><i className="fa fa-phone" /></span>
                                </div>
                                <div className="icon-content p-l20">
                                    <h4 className="mt-tilte site-text-primary">Phone</h4>
                                    <p> +91 99250 93572</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-icon-box-wraper bx-style-2 m-l20 m-b30 p-a30 left bg-white" style={{height: '100%'}}>
                                <div className="mt-icon-box-xs site-bg-primary m-b20">
                                    <span className="icon-cell text-white"><i className="fa fa-envelope" /></span>
                                </div>
                                <div className="icon-content p-l20">
                                    <h4 className="mt-tilte site-text-primary">Email</h4>
                                    <p>Iqbal@rumishippingandlogistics.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-icon-box-wraper bx-style-2 m-l20 m-b30 p-a30 left bg-white" style={{height: '100%'}}>
                                <div className="mt-icon-box-xs site-bg-primary m-b20">
                                    <span className="icon-cell text-white"><i className="fa fa-map-marker" /></span>
                                </div>
                                <div className="icon-content p-l20">
                                    <h4 className="mt-tilte site-text-primary">Address</h4>
                                    <p>106 / 1st Floor, Commercial Zone, Survey No.33/1, Plot 1, Mundra Port Main Road, Nana Kapaya, Mundra, Kutch, Gujarat - 37042</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactUs2Page;