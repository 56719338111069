import { useEffect } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import QuickContactForm from "./QuickContactForm";

function LogisticsConsultants() {

    useEffect(()=>{
        loadScript("js/custom.js")
    }, [])

    return (
        <>
            {/* Service Detail */}
<div className="section-full  p-t80 p-b50">
    <div className="container  bg-white">
        <div className="section-content">
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="mt-box">
                    <h2 className="m-t0">Logistics Consulting Services</h2>
                    <h4>Expert logistics consulting to optimize your supply chain operations.</h4>
                    <p>
                    At Rumi Shipping and Logistics, our logistics consulting services are designed to help you optimize your supply chain operations, reduce costs, and improve efficiency. We offer a range of consulting services tailored to meet your specific needs.


                        </p>
                        <p>
                        Our experienced consultants work closely with you to analyze your logistics processes, identify areas for improvement, and develop customized strategies to enhance your supply chain performance. Whether you need assistance with route optimization, inventory management, or transportation planning, we have the expertise to help you achieve your goals.

                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20">
                        <div className="item">
                            <div className="aon-thum-bx">
                                <FastImage src="images/gallery/l-pic1.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="aon-thum-bx">
                                <FastImage src="images/gallery/l-pic2.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="aon-thum-bx">
                                <FastImage src="images/gallery/l-pic3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            <div className="section-full p-t80 p-b50 bg-gray">
                <div className="container">
                    {/* TITLE START*/}
                    <div className="section-head text-center">
                    <h2 data-title="Services">Logistics Consulting Services</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/l-pic1.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-t0">Supply Chain Optimization</h4>
                                    <p>Strategies to streamline your supply chain operations and reduce costs.</p>




                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/l-pic2.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-t0">Route Optimization</h4>
                                    <p>Customized solutions to optimize your transportation routes and schedules.</p>




                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/l-pic3.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-t0">Inventory Management</h4>
                                    <p>Advanced inventory management techniques to keep track of your stock efficiently.</p>




                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t80 p-b50 bg-white ">
                <div className="container  bg-white ">
                    <div className="section-content ">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {/* Need to help */}
                                <div className="mt-box  need-help m-b40">
                                    <h2>Need any help!</h2>
                                    <p className="m-b15"><b>Find answers to frequently asked questions about our warehousing services, contacts, and more.</b></p>

    <p>
    Rumi Shipping and Logistics is here to assist you with all your logistics consulting needs. Whether you need help with optimizing your supply chain, improving your transportation processes, or understanding our services, we have the resources to help.


    </p>
    <ul className="list-check-circle primary m-b15">
    <li>Expert advice on logistics strategy and optimization.</li>
    <li>Comprehensive support for supply chain management and improvement.</li>
    </ul>
                                    <NavLink to={route.pages.contact.CONTACT} className="site-button-link">Contact Us</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                {/* Quick Contact */}
                                <QuickContactForm service={'Logistics Consultants Services'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LogisticsConsultants;