import { useEffect, useRef, useState } from "react";
import { loadScript, route, publicUrlFor } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import CountUp from "react-countup";
import SectionSlider1 from "../../sections/home/home1/section-slider1";
import { NavLink } from "react-router-dom";
import SectionHome1Testimonials from "../../sections/home/home1/section-home1-testimonials";
import _data from "../../../globals/data/data.json";
import axios from "axios";

function Home1Page() {

    const name = useRef(null);
    const email = useRef(null);
    const mobileNo = useRef(null);
    const service = useRef(null);
    const message = useRef(null);

    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            console.log('data', {
                name: name.current.value,
                mobileNo: mobileNo.current.value,
                email: email.current.value,
                service: service.current.value,
                message: message.current.value,
                formType: 'CONTACT'
            })
            const res = await axios.post("/.netlify/functions/submitForm", {
                name: name.current.value,
                mobileNo: mobileNo.current.value,
                email: email.current.value,
                service: service.current.value,
                message: message.current.value,
                formType: 'CONTACT'
            });
            console.log('res', res)
            const response = await res.data;
            setResponse(response);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            setResponse('An unexpected Error Occurred');
      }
    };

    useEffect(() => {
        loadScript("js/custom.js");
    }, [])

    return (
        <>
            {/* Slider */}
            <SectionSlider1 />

            {/* How it Work */}
            <div className="section-full  p-t80 p-b50 bg-gray bg-no-repeat bg-cover bg-white" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg15.jpg")})` }}>
                <div className="container">
                    {/* TITLE START*/}
                    <div className="section-head text-center">
                        <h2 data-title="Welcome">Rumi Shipping and Logistics </h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END*/}
                    <div className="section-content sx-how-it-work">
                        <div className="row">
                            {/* block 1 */}
                            <div className="col-lg-4 col-md-6">
                                <div className="mt-box m-b30">
                                    <div className="mt-icon-box-wraper  p-a30 left bg-white  hover-border-outer hover-border">
                                        <div className="icon-md radius m-b15">
                                            <span className="icon-cell  site-text-primary"><i className="flaticon-transport-6" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte  m-b15"><NavLink to={route.services.freightforwarding}>Freight Forwarding</NavLink></h4>
                                            <p>Efficient and reliable freight solutions tailored for your business.</p>
                                        </div>

                              







                                    </div>
                                </div>
                                <div className="mt-box m-b30">
                                    <div className="mt-icon-box-wraper  p-a30 left bg-white  hover-border-outer hover-border">
                                        <div className="icon-md radius m-b15">
                                            <span className="icon-cell  site-text-primary"><i className="flaticon-airplane" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte  m-b15"><NavLink to={route.services.custombroking}>Custom Broking</NavLink></h4>
                                            <p>Simplifying customs processes for smooth and hassle-free shipping.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* block 2 */}
                            <div className="col-lg-4 col-md-6">
                                <div className="mt-box m-b30">
                                    <div className="mt-media mid-img-verticle">
                                        <FastImage src="images/truck.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* block 3 */}
                            <div className="col-lg-4 col-md-12">
                                <div className="mt-box m-b30">
                                    <div className="mt-icon-box-wraper  p-a30 left bg-white   hover-border-outer hover-border">
                                        <div className="icon-md radius m-b15">
                                            <span className="icon-cell  site-text-primary"><i className="flaticon-ship-1" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte  m-b15"><NavLink to={route.services.warehousing}>Warehousing</NavLink></h4>
                                            <p>Secure and spacious warehousing solutions for all your storage.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="mt-box m-b30">
                                    <div className="mt-icon-box-wraper  p-a30 left bg-white   hover-border-outer hover-border">
                                        <div className="icon-md radius m-b15">
                                            <span className="icon-cell  site-text-primary"><i className="flaticon-forklift-1" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte  m-b15"><NavLink to={route.services.logisticsconsultants}>Logistics Consulting</NavLink></h4>
                                            <p>Expert advice to optimize your supply chain and logistics.</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          {/* Shipment Solution*/}
<div className="section-full p-t80 p-b50 bg-no-repeat bg-center bg-white" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})` }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-5 col-md-12">
                <div className="mt-box m-b30">
                    <div className="mt-thum-bx mt-img-effect mt-img-overlay2">
                        <FastImage src="images/background/bg12.jpg" alt="Shipment Solution" />
                    </div>
                </div>
            </div>
            <div className="col-lg-7 col-md-12">
                <div className="sx-shipment-wrap">
                    <div className="mt-box m-b25">
                        <h2 className="display-block m-b15 m-t0">We give you complete control of your shipment and help you.</h2>
                        <p className="m-b15">At Rumi Shipping and Logistics, we provide comprehensive solutions for all your shipping needs, ensuring efficiency and reliability every step of the way.</p>
                        <ul className="list-check-circle primary m-b20">
                            <li>Customizable shipping solutions tailored to your business requirements.</li>
                            <li>Experienced team ensuring smooth and hassle-free operations.</li>
                            <li>Advanced tracking system for real-time shipment updates.</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="m-b30 text-black mt-icon-box-wraper center bdr-1 bdr-solid bdr-gray-light bg-white p-a20">
                                <div className="counter font-42 font-weight-800 m-b15 site-text-primary">
                                    <CountUp end={450} duration={10} />
                                </div>
                                <h4 className="m-tb0">Delivered Packages</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="m-b30 text-black mt-icon-box-wraper center bdr-1 bdr-solid bdr-gray-light bg-white p-a20">
                                <div className="counter font-42 font-weight-800 m-b15 site-text-primary">
                                    <CountUp end={45} duration={10} />
                                </div>
                                <h4 className="m-tb0">Countries Covered</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="m-b30 text-black mt-icon-box-wraper center bdr-1 bdr-solid bdr-gray-light bg-white p-a20">
                                <div className="counter font-42 font-weight-800 m-b15 site-text-primary">
                                    <CountUp end={670} duration={10} />
                                </div>
                                <h4 className="m-tb0">Satisfied Clients</h4>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="m-b30 text-black mt-icon-box-wraper center bdr-1 bdr-solid bdr-gray-light bg-white p-a20">
                                <div className="counter font-42 font-weight-800 m-b15 site-text-primary">
                                    <CountUp end={1500} duration={10} />
                                </div>
                                <h4 className="m-tb0">Tons of Goods</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            {/* Latest Project */}
            <div className="section-full  p-tb80 bg-full-height bg-secondry bg-repeat-x city-slide-image" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg-trans-1.png")})` }}>
                <div className="container-fluid">
                    <div className="fillter-left-content">
                        <div className="filter-left-part shake_image">
                            <FastImage src="images/truck-2.png" alt="" />
                            <span className="tyre-position"><FastImage src="images/rotate-tyer.png" alt="" className="spin-tyres" /></span>
                            <FastImage className="blink-image" src="images/light-blink.png" alt="" />
                        </div>
                        <div className="container">
                            <div className="section-head text-center sx-title-mid-area">
                                <div className="mt-box  text-white m-b30">
                                <h2 className="m-tb0">For <span className="site-text-primary">10 years</span>, we have been providing exceptional services.</h2>
                                <p className="m-b15">At Rumi Shipping and Logistics, our commitment to excellence has been unwavering for over a decade, ensuring your cargo is handled with the utmost care and precision.</p>

                                </div>
                            </div>
                            <div className=" filter-carousal-1-outer">
                                <div className="filter-carousal-1">
                                    {/* IMAGE CAROUSEL START */}
                                    <div className="section-content">
                                        <div className="owl-carousel owl-carousel-filter  owl-btn-vertical-center fillter-nav-left">
                                            {/* Block 1 */}
                                            <div className="item overflow-hide">
                                                <div className="mt-box">
                                                    <div className="mt-img-effect overlay-2">
                                                        <FastImage src="images/gallery/portrait/pic1.jpg" alt="" />
                                                        <div className="overlay-2-bg bg-black" />
                                                        <div className="overlay-2-content">
                                                            <div className="p-a30 p-b20">
                                                            <h4 className="m-t0 m-b15 site-text-primary">Less than Truckload</h4>

                                                            <p className="m-b20 text-white">Customized solutions for your less-than-truckload shipments, ensuring efficiency and cost-effectiveness.</p>

                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Block 2 */}
                                            <div className="item overflow-hide">
                                                <div className="mt-box">
                                                    <div className="mt-img-effect overlay-2">
                                                        <FastImage src="images/gallery/portrait/pic2.jpg" alt="" />
                                                        <div className="overlay-2-bg bg-black" />
                                                        <div className="overlay-2-content">
                                                            <div className="p-a30 p-b20">
                                                            <h4 className="m-t0 m-b15 site-text-primary">Trucking & Warehousing</h4>

                                                            <p className="m-b20 text-white">Comprehensive trucking and warehousing services to manage your supply chain effectively.</p>

                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Block 3 */}
                                            <div className="item overflow-hide">
                                                <div className="mt-box">
                                                    <div className="mt-img-effect overlay-2">
                                                        <FastImage src="images/gallery/portrait/pic3.jpg" alt="" />
                                                        <div className="overlay-2-bg bg-black" />
                                                        <div className="overlay-2-content">
                                                            <div className="p-a30 p-b20">
                                                                <h4 className="m-t0 m-b15 site-text-primary">Sea Delivery</h4>
                                                                <p className="m-b20 text-white">Reliable sea freight services to ensure your cargo reaches its destination safely and on time.</p>

                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Block 4 */}
                                            <div className="item  overflow-hide">
                                                <div className="mt-box">
                                                    <div className="mt-img-effect overlay-2">
                                                        <FastImage src="images/gallery/portrait/pic4.jpg" alt="" />
                                                        <div className="overlay-2-bg bg-black" />
                                                        <div className="overlay-2-content">
                                                            <div className="p-a30 p-b20">
                                                                <h4 className="m-t0 m-b15 site-text-primary">Logistic Services</h4>
                                                                <p className="m-b20 text-white">Comprehensive logistics solutions to optimize your supply chain and streamline operations.</p>

                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="text-center p-t30">
                                <NavLink to={route.services.SERVICES} className="site-button">View all gallery</NavLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
           
          

            {/* Why Choose */}
            <div className="section-full bg-change-section overlay-wraper p-t80 p-b50">
                <div className="overlay-main bg-secondry opacity-09" />
                <div className="bg-changer">
                    <div className=" section-bg active" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg1.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg2.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg3.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg4.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg5.jpg")})` }} />
                    <div className="section-bg" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg6.jpg")})` }} />
                </div>
                <div className="container">
                    {/* TITLE START*/}
                    <div className="section-head text-center text-white">
                        <h2 data-title="Choose us">Why Choose Us </h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END*/}
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                                <div className="mt-icon-box-wraper  p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                                    <div className="icon-md radius m-b15">
                                        <span className="icon-cell  text-white"><i className="flaticon-people-1" /></span>
                                    </div>
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-b15 site-text-primary">Customer Satisfaction Tools</h4>

                                    <p className="text-white">Ensuring top-notch customer satisfaction with advanced tracking and support tools.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                                <div className="mt-icon-box-wraper   p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                                    <div className="icon-md radius m-b15 site-text-primary">
                                        <span className="icon-cell  text-white"><i className="flaticon-pencil" /></span>
                                    </div>
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-b15 site-text-primary">Management & Reporting</h4>

                                    <p className="text-white">Efficient management and detailed reporting to keep you informed every step of the way.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                                <div className="mt-icon-box-wraper  p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                                    <div className="icon-md radius m-b15">
                                        <span className="icon-cell  text-white"><i className="flaticon-wallet" /></span>
                                    </div>
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-b15 site-text-primary">Freight Payment Options</h4>

                                    <p className="text-white">Flexible freight payment options to suit your business needs.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                                <div className="mt-icon-box-wraper  p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                                    <div className="icon-md radius m-b15">
                                        <span className="icon-cell  text-white"><i className="flaticon-solution" /></span>
                                    </div>
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-b15 site-text-primary">Compliance Solutions</h4>
                                    <p className="text-white">Ensuring full compliance with all regulations for smooth and hassle-free logistics.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                                <div className="mt-icon-box-wraper   p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                                    <div className="icon-md radius m-b15">
                                        <span className="icon-cell  text-white"><i className="flaticon-time-passing" /></span>
                                    </div>
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-b15 site-text-primary">Timely Delivery</h4>

                                    <p className="text-white">Prompt and reliable delivery to keep your business moving forward.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                                <div className="mt-icon-box-wraper  p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                                    <div className="icon-md radius m-b15">
                                        <span className="icon-cell  text-white"><i className="flaticon-customer-service" /></span>
                                    </div>
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-b15 site-text-primary">24/7 Service</h4>

                                    <p className="text-white">Round-the-clock service to ensure your logistics needs are always met.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonials */}
            <SectionHome1Testimonials />

            {/* Contact Us  */}
            <div className="section-full bg-white bg-right-center bg-no-repeat" style={{ backgroundImage: `url(${publicUrlFor("images/background/bg-trans-2.png")})` }}>
                <div className="container">
                    <div className="row conntact-home">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="contact-home1-left bg-secondry">
                                <div className="contact-tag-line bg-white site-text-primary">Contact us</div>
                                <div className="section-content p-lr30 p-tb30">
                                    <div className="call-back-form" style={{ zIndex: 1, position: 'relative' }}>
                                        <h2 className="text-white m-t0">Contact Us</h2>
                                        <div className="form-transparent">
                                            <div className="form-group">
                                                <input name="username" ref={name} type="text" required className="form-control" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="text" ref={email} className="form-control" required placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <input name="phone" type="text" ref={mobileNo} className="form-control" required placeholder="Phone" />
                                            </div>
                                            <div className="form-group">
                                                <select name="services" ref={service} style={{ backgroundColor: '#020d26' }} className="form-control" required placeholder="Select Service" >
                                                    <option value="">Select Service</option>
                                                    <option value="Transportation Services">Transportation Services</option>
                                                    <option value="Custom Broking">Custom Broking</option>
                                                    <option value="Freight Forwarding">Freight Forwarding</option>
                                                    <option value="Logistics Consultancy Services">Logistics Consultancy Services</option>
                                                    <option value="Warehousing Services">Warehousing Services</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" ref={message} rows={3} placeholder="Message" defaultValue={""} />
                                            </div>
                                            <button onClick={handleSubmit} disabled={loading} className="site-button">
                                                <span className="font-weight-700 inline-block  p-lr15">Submit</span>
                                            </button>
                                            {response && <div className="px-2 py-2 mt-5 cda-content-area" style={{ backgroundColor: '#ffffff',
                                                borderRadius: '5px', textAlign: 'center', display: 'flex', justifyContent: 'space-between', color: '#020d26'
                                            }}>{<><span className="sub-title">{response}</span> <span class="close_rightmenu" style={{ cursor: 'pointer' }} onClick={() => { setResponse(null) }}><i class="icofont-close-circled"></i></span></>}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-content  contact-home1-right">
                            <div className="mt-box">
                                <div className="mt-media">
                                    <FastImage src="images/con-1.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
           
           
        </>
    )
}
export default Home1Page;