import { useEffect } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import QuickContactForm from "./QuickContactForm";

function Warehousing() {

    useEffect(() => {
        loadScript("js/custom.js")
    }, [])

    return (
        <>
            {/* Service Detail */}
            <div className="section-full  p-t80 p-b50">
                <div className="container  bg-white">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="mt-box">
                                    <h2 className="m-t0">Warehousing Services</h2>
                                    <h4>Secure and strategically located warehousing solutions to store your goods.</h4>
                                    <p>
                                        At Rumi Shipping and Logistics, our warehousing services provide secure and efficient storage solutions for your cargo. We offer a range of warehousing options, including bonded and non-bonded facilities, to meet your specific needs.

                                    </p>
                                    <p>
                                        Our experienced team ensures that your goods are stored in optimal conditions, with advanced inventory management systems to keep track of your stock. Whether you need short-term or long-term storage, we have the facilities and expertise to ensure your goods are safe and accessible.

                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20">
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/w-pic1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/w-pic2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="aon-thum-bx">
                                            <FastImage src="images/gallery/w-pic3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full p-t80 p-b50 bg-gray">
                <div className="container">
                    {/* TITLE START*/}
                    <div className="section-head text-center">
                        <h2 data-title="Services">Warehousing Services</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                <FastImage src="images/gallery/w-pic1.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Bonded Warehousing</h4>
                                        <p>Secure bonded warehousing solutions for goods in transit or awaiting clearance.</p>




                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                <FastImage src="images/gallery/w-pic2.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Inventory Management</h4>
                                        <p>Advanced inventory management systems to keep track of your stock accurately.</p>




                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                <FastImage src="images/gallery/w-pic3.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                        <h4 className="mt-tilte m-t0">Temperature-Controlled Storage</h4>
                                        <p>Specialized storage solutions for temperature-sensitive goods, ensuring optimal conditions.</p>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t80 p-b50 bg-white ">
                <div className="container  bg-white ">
                    <div className="section-content ">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {/* Need to help */}
                                <div className="mt-box  need-help m-b40">
                                    <h2>Need any help!</h2>
                                    <p className="m-b15"><b>Find answers to frequently asked questions about our warehousing services, contacts, and more.</b></p>

                                    <p>
                                        Rumi Shipping and Logistics is here to assist you with all your warehousing needs. Whether you need help with storage solutions, tracking your inventory, or understanding our services, we have the resources to help.

                                    </p>
                                    <ul className="list-check-circle primary m-b15">
                                        <li>Expert advice on warehousing logistics and inventory management.</li>
                                        <li>Comprehensive support for storage and distribution coordination.</li>
                                    </ul>
                                    <NavLink to={route.pages.contact.CONTACT} className="site-button-link">Contact Us</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                {/* Quick Contact */}
                                <QuickContactForm service={'Warehousing Services'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Warehousing;