import { route } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";

function SectionHome3WhyChooseUs() {
    return (
        <div className="section-full bg-gray p-t80 p-b50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        {/* TITLE START*/}
                        <div className="section-head text-left">
                            <h2>Why Choose Us</h2>
                            <div className="mt-separator-outer">
                                <div className="mt-separator site-bg-primary" />
                            </div>
                        </div>
                        {/* TITLE END*/}
                        <div className="section-content sx-why-choose">
                            <div className="mt-box">
                                <div className="mt-info bg-white text-left p-a20 m-b30">
                                    <h4>Expert Freight Forwarding</h4>
                                    <p>Experience seamless worldwide delivery with our expert freight forwarding services. We ensure timely and efficient delivery of your cargo across the globe.</p>
                                  
                                </div>
                            </div>
                            <div className="mt-box m-b30">
                                <div className="mt-info bg-white text-left p-a20">
                                    <h4>Safe and Compliant Operations</h4>
                                    <p>We prioritize safety and compliance in every operation. Trust us for secure handling and compliant shipping practices that meet international standards.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        {/* TITLE START*/}
                        <div className="section-head text-left">
                            <h2>Our Skills</h2>
                            <div className="mt-separator-outer">
                                <div className="mt-separator site-bg-primary" />
                            </div>
                        </div>
                        {/* TITLE END*/}
                        <div className="section-content clearfix">
                            <div className="p-b0">
                                <div className="mt-box">
                                    <h2 className="m-t0"><span className="site-text-primary">10 years</span> of experience in Logistics Services</h2>
                                </div>
                                <div className="our-exp">
                                    <span className="progressText text-black"><b>Ground Transport</b></span>
                                    <div className="progress m-b30 m-t10">
                                        <div className="progress-bar site-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>100%</div>
                                    </div>
                                    <span className="progressText text-black"><b>Cargo</b></span>
                                    <div className="progress m-b30 m-t10">
                                        <div className="progress-bar site-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>100%</div>
                                    </div>
                                    <span className="progressText text-black"><b>Logistic Services</b></span>
                                    <div className="progress m-b30 m-t10">
                                        <div className="progress-bar site-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>100%</div>
                                    </div>
                                    <span className="progressText text-black"><b>Warehousing</b></span>
                                    <div className="progress m-b30 m-t10">
                                        <div className="progress-bar site-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>100%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SectionHome3WhyChooseUs;
