import { useState } from "react";
import FsLightbox from "fslightbox-react";
import { Link } from "react-router-dom";

export default function Gallery() {
  const imgArray = [
    "assets/images/gallery/thumb/g-1.png",
   "assets/images/gallery/thumb/g-2.png",
   "assets/images/gallery/thumb/g-3.png",
   "assets/images/gallery/thumb/g-4.png",
   "assets/images/gallery/thumb/g-5.png",
   "assets/images/gallery/thumb/g-6.png",
   "assets/images/gallery/thumb/g-7.png",
   "assets/images/gallery/thumb/g-8.png",
   "assets/images/gallery/thumb/g-9.png",
  ];
  const [images, setImages] = useState(imgArray);
  const galleryone = imgArray[0];
  const [img, setImg] = useState();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [img4, setImg4] = useState();
  const [img5, setImg5] = useState();
  const [img6, setImg6] = useState();
  const [img7, setImg7] = useState();
  const [img8, setImg8] = useState();
  const [img9, setImg9] = useState();
  const [img10, setImg10] = useState();
  const [img11, setImg11] = useState();

  const [toggler, setToggler] = useState(false);

  const [activeImage, setActiveImage] = useState(1);

  const getImageComponent = (image, index) => {
    return (
      <div className="col-md-4 col-sm-6 col-xs-12">
        <div className="img" data-aos="fade-up" data-aos-duration={1500}>
          <img className="img-fluid" src={image} alt="img" />
          <Link
            href="#"
            onClick={() => setImg(true)}
            className="lightbox-image"
            data-fancybox="gallery"
          >
            <i className="icofont-ui-zoom-in" />
          </Link>
          <Link
            href="#"
            onClick={() => {
              setToggler(!toggler);
              setActiveImage(index + 1);
            }}
          ></Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="row_am gallery_section">
        <div className="container">
          <div className="row">
            {images.map((image, index) => {
              return getImageComponent(image, index);
            })}
          </div>
        </div>
      </section>
      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />
      {/* about-section end */}
    </>
  );
}
