import { useEffect } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import QuickContactForm from "./QuickContactForm";

function FreightForwarding() {

    useEffect(()=>{
        loadScript("js/custom.js")
    },[])

    return (
        <>
            {/* Service Detail */}
<div className="section-full  p-t80 p-b50">
    <div className="container  bg-white">
        <div className="section-content">
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="mt-box">
                        <h2 className="m-t0">Freight Forwarding Services</h2>
                        <h4>Ensuring seamless and efficient transportation of your goods worldwide.</h4>
                        <p>
                            At Rumi Shipping and Logistics, we provide expert freight forwarding services to ensure your shipments reach their destination safely and on time. Our team handles all aspects of the transportation process, from documentation and customs clearance to tracking and delivery.
                        </p>
                        <p>
                            With a global network of partners and carriers, we offer flexible and reliable solutions tailored to your specific needs. Whether it's air, sea, or road freight, we have the expertise to manage your logistics efficiently and cost-effectively.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="owl-carousel service-detail-carousel owl-btn-vertical-center owl-dots-bottom-center m-b20">
                        <div className="item">
                            <div className="aon-thum-bx">
                                <FastImage src="images/gallery/ff-pic1.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="aon-thum-bx">
                                <FastImage src="images/gallery/ff-pic2.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="aon-thum-bx">
                                <FastImage src="images/gallery/ff-pic3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            <div className="section-full p-t80 p-b50 bg-gray">
                <div className="container">
                    {/* TITLE START*/}
                    <div className="section-head text-center">
                        <h2 data-title="Services">Freight Forwarding Services</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div>
                    {/* TITLE END*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/ff-pic1.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-t0">Customs Brokerage</h4>
                                    <p>Efficient and accurate customs clearance to ensure your goods move smoothly.</p>


                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/ff-pic2.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-t0">International Freight Forwarding</h4>
                                    <p>Comprehensive global shipping solutions for air, sea, and road freight.</p>


                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="mt-box bdr-1 bdr-solid bdr-gray-light m-b30 mt-service-2">
                                <div className="mt-media">
                                    <FastImage src="images/gallery/ff-pic3.png" alt="" />
                                </div>
                                <div className="mt-icon-box-wraper  p-a30 left bg-white">
                                    <div className="icon-content">
                                    <h4 className="mt-tilte m-t0">Warehousing</h4>
                                    <p>Secure and strategically located warehousing solutions to store your goods.</p>


                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
























            
            <div className="section-full p-t80 p-b50 bg-white ">
                <div className="container  bg-white ">
                    <div className="section-content ">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                {/* Need to help */}
                                <div className="mt-box  need-help m-b40">
                                    <h2>Need any help!</h2>
                                    <p className="m-b15"><b>Find answers to frequently asked questions about our freight forwarding services, contacts, and more.</b></p>
    <p>
        Rumi Shipping and Logistics is here to assist you with all your freight forwarding needs. Whether you need help with customs clearance, tracking your shipment, or understanding our services, we have the resources to help.
    </p>
    <ul className="list-check-circle primary m-b15">
        <li>Expert advice on international shipping and customs regulations.</li>
        <li>Comprehensive support for documentation and compliance.</li>
    </ul>
                                    <NavLink to={route.pages.contact.CONTACT} className="site-button-link">Contact Us</NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                {/* Quick Contact */}
                                <QuickContactForm service={'Freight Forwarding Services'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FreightForwarding;