import { useEffect } from "react";
import FastImage from "../../../../globals/elements/fastimg";
import { loadScript, publicUrlFor } from "../../../../globals/constants";
import _data from "../../../../globals/data/data.json";
import Gallery from "../../gallery/page";

function Gallery1Page() {

    const { gallery } = _data;

    useEffect(() => {
        loadScript("js/masonry-grid.js");
        loadScript("js/custom.js");
    },[])

    return (
        <div className="section-full p-t87 p-b70">
            <Gallery />
        </div>
    )
}
export default Gallery1Page;