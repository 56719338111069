import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";

function SectionHome3WhatWeDo() {
    return (
        <div className="section-full p-t80 p-b50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="mt-box m-b30">
                            <div className="mt-thum-bx mt-img-effect">
                                <FastImage src="images/about/section-header.png" alt="About Us" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="mt-box sx-what-wedo">
                            <span className="site-text-primary display-block title-second text-uppercase font-20 font-weight-600 m-b15">What We Do</span>
                            <h2 className="m-t0">Your Reliable Shipping and Logistics Partner</h2>
                            <p>
                                At Rumi Shipping and Logistics, we specialize in providing comprehensive shipping and logistics solutions tailored to your needs. Our mission is to ensure seamless transportation, efficient customs broking, secure warehousing, and expert logistics consulting.
                            </p>
                            <p>
                                Whether you require freight forwarding across the globe, efficient customs clearance, secure warehousing solutions, or expert logistics consulting, we are dedicated to delivering reliable and efficient services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SectionHome3WhatWeDo;

